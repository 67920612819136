
import { defineComponent, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Home/Footer.vue";

export default defineComponent({
  name: "Privacy",
  components: {
    Navbar,
    Footer,
  },

  setup() {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  },
});
